.loggedout-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 48px;
    margin-bottom: 48px;
}

.loggedout-information {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loggedout-logo {
    width: 72px;
    height: 72px;
}

.loggedout-title {
    margin-top: 25px;

    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 150%;
    text-align: center;
    color: #222222;
}

.loggedout-description {
    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #888888;
}

.login-form {
    display: flex;
    flex-direction: column;

    max-width: 325px;
    width: 80%;
    margin: 22px 25px 22px 25px;
}

.login-form>.input-field {
    display: flex;
    flex-direction: column;

    flex-shrink: 1;
    flex-grow: 1;

    margin-top: 22px;
}

.login-form>.login-error-message {
    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-style: normal;
    white-space: pre;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    line-height: 150%;
    color: rgba(248, 81, 73, 100);
}

.login-form>.input-field>.input-field-label {
    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #888888;
}

.login-form>.input-field>.input-field-value {
    box-sizing: border-box;
    flex-shrink: 1;
    flex-grow: 1;
    align-items: center;
    padding: 10px;
    gap: 10px;

    height: 50px;
    border: 1.5px solid #EEEEEE;
    border-radius: 13px;

    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-size: 16px;
    color: #222222;
}

.login-form-button {
    margin-top: 30px;
    height: 50px;

    /* Key Color */
    background: #760023;
    border-radius: 13px;
    border-width: 0px;

    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;

    /* identical to box height, or 24px */
    text-align: center;
    color: #FFFFFF;
}

.qr-login-text {
    margin-left: 8px;
}

.qr-login-form-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    height: 50px;

    /* Key Color */
    background: #ffffff;
    border-radius: 13px;
    border: 1px solid #760023;

    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;

    /* identical to box height, or 24px */
    text-align: center;
    color: #760023;
}

.loggedout-bottom {
    margin-top: 16px;
    display: flex;
    flex-direction: row;

    flex-shrink: 1;
    flex-grow: 1;
    justify-content: right;
    -webkit-justify-content: right;
}

.loggedout-private-info-policy {
    margin-right: 8px;
    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-style: normal;
    cursor: pointer;
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    color: #888888;
}