.test-answer-recorder {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    background: #FFFFFF;
    box-shadow: 0px 7px 30px -3px rgba(0, 0, 0, 0.1);
    border-radius: 36px;
    padding: 14px 24px 14px 24px;
}

.test-answer-recorder-timeline {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    align-self: center;
    margin-right: 24px;
}

.test-answer-recorder-timeline-top {
    display: flex;
    flex-direction: column;
}

.test-answer-recorder-timeline-bottom {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 1;
    margin-top: 8px;
    margin-bottom: 8px;
}

.test-answer-recorder-timeline-description {
    display: flex;
    flex-direction: row;
}

.test-answer-recorder-button {
    display: flex;
    background-color: #760023;
    width: 42px;
    height: 42px;
    border-radius: 21px;
}

.test-answer-recorder-button>svg {
    display: flex;
    align-self: center;
    width: 42px;
    font-size: 24px;
    color: #ffffff;
}

.test-answer-recorder-completed-description {
    display: flex;
    margin-right: 12px;
    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    color: #888888;
}

.test-answer-recorder-completed-retry-button {
    margin-left: 8px;
    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0em;
    color: #760023;
}

.test-answer-recorder-completed-spinner {
    margin-left: 2px;
    width: 12px;
    height: 12px;
    background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/355309/loading.png);
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-animation: spin 1000ms infinite linear;
    -moz-animation: spin 1000ms infinite linear;
    -ms-animation: spin 1000ms infinite linear;
    animation: spin 1000ms infinite linear;
}

.test-answer-recorder-loading-button {
    background-color: #ffffff;
}

.test-answer-recorder-loading-spinner {
    margin-top: 5px;
    margin-left: 5px;
    width: 32px;
    height: 32px;
    border: 3px solid #76002360;
    box-sizing: border-box;
    border-top-color: #760023;
    border-radius: 100%;
    -webkit-animation: spin 1000ms infinite linear;
    -moz-animation: spin 1000ms infinite linear;
    -ms-animation: spin 1000ms infinite linear;
    animation: spin 1000ms infinite linear;
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-ms-keyframes spin {
    100% {
        -ms-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes sparkle {
    0% {
        box-shadow: 0 0 3px 2px #76002388;
    }

    50% {
        box-shadow: 0 0 5px 3px #760023aa;
    }

    100% {
        box-shadow: 0 0 3px 2px #76002388;
    }
}

.recording-active {
    /* 기본 테두리 설정 */
    animation: sparkle 2s infinite ease-in-out;
    /* 애니메이션 적용 */
}

.test-answer-recorder-description {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    margin-right: 16px;
    flex-grow: 1;
    flex-shrink: 1;

    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #888888;
}

.test-answer-recorder-seconds {
    display: flex;
    flex-direction: row;
    align-content: center;
    font-family: "ProximaNova", "Helvetica Neue", Helvetica, "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height */

    flex-grow: 1;
    flex-shrink: 1;
    color: #760023;
}

.test-question-card {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    padding: 18px 12px 18px 12px;

    background: #FFFFFF;
    border-radius: 16px;
    background: #FFFFFF;
    box-shadow: 0px 7px 30px -3px rgba(0, 0, 0, 0.1);
}

.test-question-card.solved {
    border: 0.5px solid #760023;
    box-shadow: 0px 7px 30px -3px rgba(0, 0, 0, 0.1);
}

.test-question-area {
    display: flex;
    flex-direction: column;
}

.test-question-title-area {
    display: flex;
    flex-direction: row;
}

.test-question-content-area {
    display: flex;
    flex-direction: row;
}

.test-question-number {
    border-radius: 14px;
    width: 28px;
    height: 28px;
    background: #EEEEEE;
    font-family: "ProximaNova", "Helvetica Neue", Helvetica, "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 28px;
    text-align: center;

    color: #000000;
}

.test-question-title-label {
    margin-left: 8px;
    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    flex-grow: 1;
    flex-shrink: 1;

    color: #000000;
}

.test-question-content-label {
    margin-left: 8px;
    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    flex-grow: 1;
    flex-shrink: 1;

    color: #000000;
}

.test-question-audio {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    background: #FFFFFF;
    box-shadow: 0px 7px 30px -3px rgba(0, 0, 0, 0.1);
    border-radius: 36px;
    padding: 14px 24px 14px 24px;
}

.test-question-audio-timeline {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    align-self: center;
    margin-right: 24px;
}

.test-question-audio-timeline-top {
    display: flex;
    flex-direction: column;
}

.test-question-audio-timeline-bottom {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 1;
    margin-top: 8px;
    margin-bottom: 8px;
}

.test-question-audio-timeline-description {
    display: flex;
    flex-direction: row;
}

.test-question-audio-button {
    display: flex;
    background-color: #222222;
    width: 42px;
    height: 42px;
    border-radius: 21px;
}

.test-question-audio-button>svg {
    display: flex;
    align-self: center;
    width: 42px;
    font-size: 24px;
    color: #ffffff;
}

.test-question-audio-completed-description {
    display: flex;
    align-self: right;
    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    color: #888888;
}

.test-question-audio-completed-retry-button {
    margin-left: 4px;
    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    color: #760023;
}

.test-question-audio-completed-spinner {
    margin-left: 2px;
    width: 12px;
    height: 12px;
    background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/355309/loading.png);
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-animation: spin 1000ms infinite linear;
    -moz-animation: spin 1000ms infinite linear;
    -ms-animation: spin 1000ms infinite linear;
    animation: spin 1000ms infinite linear;
}

.test-question-audio-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 16px;
    flex-grow: 1;
    flex-shrink: 1;

    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #888888;
}

.test-question-audio-seconds {
    display: flex;
    flex-direction: row;
    align-content: center;
    font-family: "ProximaNova", "Helvetica Neue", Helvetica, "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height */

    flex-grow: 1;
    flex-shrink: 1;
    color: #222222;
}

.test-question-resources {
    margin-top: 16px;
    width: 100%;
}

.test-question-resources>audio {
    width: 100%;
}

.test-question-resources>video {
    width: 100%;
}

.test-question-resources>.test-question-image {
    position: relative;
    display: flex;
    width: 100%;
    border-radius: 16px;
    background-color: #000000;
    overflow: hidden;
}

.test-question-video {
    position: relative;
    display: flex;
    width: 100%;
    border-radius: 16px;
    background-color: #000000;
    overflow: hidden;
}

.test-question-resources>.test-question-image:first-child {
    width: 100%;
    aspect-ratio: 16/9;
}

.test-question-resources>.test-question-image>img {
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: contain;
    object-position: center;
}

.test-question-image-full-screen {
    position: absolute;
    top: 12px;
    right: 12px;
}

.test-question-mark {
    margin-top: 16px;
    align-self: flex-end;
    background: #EEEEEE;
    border-radius: 7px;

    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 13px;
    text-align: center;
    padding: 8px 12px 8px 12px;
    /* identical to box height */

    color: #000000;

}

.test-question-divider {
    width: 100%;
    height: 1px;
    margin: 14px 0px 14px 0px;
    background-color: #EEEEEE;
}

.test-answer-area {
    display: flex;
    flex-direction: column;
}

.test-answer-text {
    resize: none;
    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    padding: 15px 20px 15px 20px;
    max-height: 153px;
    border: 1.5px solid #EEEEEE;
    border-radius: 13px;
    color: #222222;
}

.test-answer-recorder-timeline-controls {
    font-family: "ProximaNova", "Helvetica Neue", Helvetica, "Segoe UI", Arial, sans-serif;
    font-size: 16px;
    line-height: 18px;
    height: 0px;
    color: #55606E;
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    margin-left: 6px;
    align-items: center;
}

.test-answer-recorder-timeline-controls-pin {
    top: -2px;
    height: 8px;
    width: 8px;
    border-radius: 4px;
    background-color: #760023;
    position: absolute;
    pointer-events: all;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.32);
}

.test-answer-recorder-timeline-controls-pin {
    left: 0%;
}

.test-question-audio-timeline-controls {
    font-family: "ProximaNova", "Helvetica Neue", Helvetica, "Segoe UI", Arial, sans-serif;
    font-size: 16px;
    line-height: 18px;
    height: 0px;
    color: #55606E;
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    margin-left: 6px;
    align-items: center;
}

.test-question-audio-timeline-controls-pin {
    top: -2px;
    height: 8px;
    width: 8px;
    border-radius: 4px;
    background-color: #760023;
    position: absolute;
    pointer-events: all;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.32);
}

.test-question-audio-timeline-controls-pin {
    left: 0%;
}


.test-question-choices {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: 36px;
    margin-right: 36px;
}

.test-question-choices-choice {
    display: flex;
    flex-grow: 1;
    margin-top: 8px;
}

.test-question-choices-choice-index {
    display: flex;
    color: #222222;
    font-family: "ProximaNova", "Helvetica Neue", Helvetica, "Segoe UI", Arial, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: bold;
    line-height: 150%;
}

.test-question-choices-choice-content {
    display: flex;
    margin-left: 6px;
    color: #888888;
    font-family: "ProximaNova", "Helvetica Neue", Helvetica, "Segoe UI", Arial, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.test-answer-choices {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 8px;
    margin-right: 8px;
}

.test-answer-choices-choice-index {
    cursor: pointer;
    margin-right: 12px;
    border-radius: 18px;
    border: #eeeeee 1px solid;
    width: 36px;
    height: 36px;
    background: #ffffff;

    font-family: "ProximaNova", "Helvetica Neue", Helvetica, "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 36px;
    text-align: center;

    color: #222222;
}

.test-answer-choices-choice-index.solved {
    margin-right: 12px;
    border: #eeeeee 1px solid;
    width: 36px;
    height: 36px;
    background: #760023;

    font-family: "ProximaNova", "Helvetica Neue", Helvetica, "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 36px;
    text-align: center;

    color: #ffffff;
}