.user-info {
    flex-direction: column;
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 8px;
}

.user-info-name {
    color: #888;
    font-family: ProximaNova, Helvetica Neue, Helvetica, Segoe UI, Arial, sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;

    width: calc(100% - 50px);
    max-width: 568px;
    padding-left: 16px;
}

.test-card {
    display: flex;
    flex-direction: column;
    margin: 8px 25px 8px 25px;

    width: calc(100% - 50px);
    max-width: 568px;

    background: #FFFFFF;
    box-shadow: 0px 7px 30px -3px rgba(0, 0, 0, 0.1);
    border-radius: 15px;

    position: relative;
    animation: fadeIn 0.5s ease-out;
    animation-fill-mode: both;
}

.test-card.ready {
    border: 1px solid #760023;
}

.test-card-icon {
    display: flex;
    width: 40px;
    flex-direction: row;
    justify-content: center;
}

.test-card-top {
    display: flex;
    flex-direction: row;
    padding: 16px 16px 19px 16px;
}

.test-card-bottom {
    display: flex;
    flex-direction: row;
    padding: 19px 14px 19px 14px;
}

.test-card-top.completed {
    opacity: 0.5;
}

.test-card-bottom.completed {
    opacity: 0.5;
}

.test-card-top-left {
    justify-content: space-between;
    margin-top: 3px;
    margin-right: 24px;
    flex-grow: 1;
    flex-shrink: 1;
}

.test-card-bottom-left {
    margin-right: 16px;
    flex-grow: 1;
    flex-shrink: 1;
}

.test-card-bottom-right {
    display: flex;
    flex-direction: row;
}

.test-card-title {
    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    color: #222222;
}

.test-card-mark {
    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* or 20px */
    text-align: center;

    /* Key Color */
    color: #760023;
}

.test-card-date {
    margin-top: 8px;

    font-family: "ProximaNova", "Helvetica Neue", Helvetica, "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 12px;
    color: #888888;
}

.test-card-divider {
    height: 0.5px;
    margin: 0px 15px 0px 15px;
    background-color: #EEEEEE;
}

.test-card-class {
    font-family: "ProximaNova", "Helvetica Neue", Helvetica, "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 13px;
    /* identical to box height */

    color: #222222;
}

.test-card-description {
    display: flex;
    flex-direction: row;

    margin-top: 6px;
}

.test-card-numbers-of-question {
    font-family: "ProximaNova", "Helvetica Neue", Helvetica, "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    /* identical to box height */
    color: #888888;
}

.test-card-time-limits {
    align-content: center;
    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    margin-left: 4px;

    /* Key Color */
    color: #760023;
}

.test-card-completed {
    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* or 20px */
    text-align: center;

    /* Key Color */
    color: #760023;
}

.test-card-action {
    width: 82px;
    height: 32px;

    background: #760023;
    border-radius: 7px;
    color: #FFFFFF;
    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 32px;
    text-align: center;
}