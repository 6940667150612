.test-area {
    display: flex;
    flex-direction: column;
    padding: 32px 24px 132px 24px;
    width: calc(100% - 48px);
    max-width: 568px;
    align-self: center;
}

.test-header {
    display: flex;
    flex-direction: column;
}

.test-time-remained {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    border: 1px solid #EEEEEE;
    border-radius: 7px;
    width: 128px;
    height: 36px;
}

.test-time-remained-title {
    border-radius: 7px 0px 0px 7px;
    background: #760023;
    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 36px;
    text-align: center;
    color: #FFFFFF;
    width: 64px;
}

.test-time-remained-time {
    border-radius: 7px 0px 0px 7px;
    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 36px;
    text-align: center;
    color: #888888;
    width: 64px;
}

.test-questions-list {
    display: flex;
    flex-direction: column;
}

.test-question-card {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    padding: 18px 12px 18px 12px;

    background: #FFFFFF;
    border-radius: 16px;
    background: #FFFFFF;
    box-shadow: 0px 7px 30px -3px rgba(0, 0, 0, 0.1);
}

.test-question-card.solved {
    border: 0.5px solid #760023;
    box-shadow: 0px 7px 30px -3px rgba(0, 0, 0, 0.1);
}

.test-question-area {
    display: flex;
    flex-direction: column;
}

.test-question-number {
    border-radius: 14px;
    width: 28px;
    height: 28px;
    background: #EEEEEE;
    font-family: "ProximaNova", "Helvetica Neue", Helvetica, "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 28px;
    text-align: center;

    color: #000000;
}

.test-question-mark {
    margin-top: 16px;
    align-self: flex-end;
    background: #EEEEEE;
    border-radius: 7px;

    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 13px;
    text-align: center;
    padding: 8px 12px 8px 12px;
    /* identical to box height */

    color: #000000;

}

.test-question-divider {
    width: 100%;
    height: 1px;
    margin: 14px 0px 14px 0px;
    background-color: #EEEEEE;
}

.test-answer-area {
    display: flex;
    flex-direction: column;
}

.test-submit {
    margin-top: 32px;
    height: 60px;

    background: #760023;
    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 60px;
    text-align: center;
    /* identical to box height */

    color: #FFFFFF;
    border-radius: 12px;
}