.private-policy-container {
    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-style: normal;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #666666;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.private-policy-container>div {
    padding: 24px;
    max-width: 568px;
    text-wrap: pretty;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}