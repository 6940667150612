.test-complete-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 48px);
    width: 100%;
}

.test-complete-information {
    display: flex;
    margin-top: 16vh;
    margin-left: 24px;
    margin-right: 24px;

    flex-direction: column;
    align-items: center;
    align-self: center;
    max-width: 568px;
    width: 100%;
}

.test-complete-logo {
    width: 98px;
    height: 98px;
}

.test-complete-title {
    margin-top: 8px;
    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    text-align: center;
    word-wrap: break-word;
    overflow-wrap: break-word;
    color: #222222;
}

.test-complete-description {
    margin-top: 8px;
    padding-left: 24px;
    padding-right: 24px;
    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    overflow-wrap: anywhere;
    text-align: center;
    white-space: normal;
    color: #888888;
}

.test-complete-confirm-button {
    background: #760023;
    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 60px;
    text-align: center;

    color: #FFFFFF;
    border-radius: 12px;
}

/* 화면의 너비가 568px 이하일 때 적용할 스타일 */
@media (max-width: 568px) {
    .test-complete-confirm-button {
        position: fixed;
        bottom: 34px;
        right: 24px;
        left: 24px;
        height: 60px;
    }
}

/* 화면의 너비가 568px 초과일 때 적용할 스타일 */
@media (min-width: 569px) {
    .test-complete-confirm-button {
        max-width: 568px;
        align-self: center;
        width: 100%;
        margin-top: 10vh;
    }
}