.navigation-bar {
    top: 0px;
    left: 0px;
    right: 0px;
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 48px;
    padding: 0px 24px 0px 24px;
    background-color: #ffffff;
    box-shadow: 0px 1px 30px -1px rgba(0, 0, 0, 0.025);
    z-index: 99;
}

.navigation-bar-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 568px;
    width: 100%;
}

.navigation-title {
    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    text-align: center;

    color: #222222;
}

.navigation-left-item {
    width: 84px;
}

.navigation-right-item {
    display: flex;
    flex-direction: row-reverse;
    width: 84px;
}

.navigation-item {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    width: 30px;
    height: 30px;
    background: rgba(118, 0, 35, 0.3);
}

.navigation-item-none {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    width: 30px;
    height: 30px;
}

.navigation-item-text {
    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    text-align: center;

    color: #888888;
}