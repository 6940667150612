/* https://dev.to/webdeasy/top-20-css-buttons-animations-f41 */

html,
body {
  max-width: 100%;
  overflow-x: hidden;
  background-color: white;
}

@supports (-webkit-touch-callout: none) {

  body,
  html {
    background-color: white;
  }
}

.button-pointer:hover {
  cursor: pointer;
}

.window-toast {
  font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: #222222;
}

/* Ripple effect */

.ripple-key-color-outlined {
  background-position: center;
  transition: background 0.8s;
}

.ripple-key-color-outlined:hover {
  cursor: pointer;
}

.ripple-key-color-outlined:active {
  background-color: #bbbbbb;
  background-size: 100%;
  transition: background 0s;
}

.ripple-key-color {
  background-position: center;
  transition: background 0.8s;
}

.ripple-key-color:hover {
  cursor: pointer;
  background: #760023 radial-gradient(circle, transparent 1%, #760023 1%) center/15000%;
}

.ripple-key-color:active {
  background-color: #a90056;
  background-size: 100%;
  transition: background 0s;
}

.ripple-black-color {
  background-position: center;
  transition: background 0.8s;
}

.ripple-black-color:hover {
  cursor: pointer;
  background: #222222 radial-gradient(circle, transparent 1%, #222222 1%) center/15000%;
}

.ripple-black-color:active {
  background-color: #340036;
  background-size: 100%;
  transition: background 0s;
}

.scale-button:active {
  transform: scale(0.95);
  /* 눌렀을 때 작아지는 효과 */
}

.scale-button:hover {
  cursor: pointer;
}

.scale-button:focus:not(:active) {
  transform: scale(1.1);
  /* 포커스되었을 때 크게 커지는 효과 */
}

.scale-button:hover:not(:active):not(:focus) {
  transform: scale(1.05);
  /* 마우스가 올라갔을 때 약간 커지는 효과 */
}

.scale-button:active {
  transition: transform 0.2s ease-in-out;
  /* 작아졌을 때의 애니메이션 효과 지정 */
}

.scale-button:focus:not(:active) {
  transition: transform 0.2s ease-in-out;
  /* 다시 커졌을 때의 애니메이션 효과 지정 */
}

.right-enter {
  transform: translateX(100%);
}

.right-enter-active {
  transform: translateX(0);
  transition: transform 300ms ease-in-out;
}

.right-exit {
  transform: translateX(0);
}

.right-exit-active {
  transform: translateX(-100%);
  transition: transform 300ms ease-in-out;
}