div:has(> .alert-dialog-container) {
    width: 100%;
    border-radius: 8px;
    max-width: 320px;
}

.alert-dialog-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.alert-dialog-content {
    margin: 26px;
}

.alert-dialog-title {
    white-space: pre-wrap;
    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    flex-grow: 1;
    flex-shrink: 1;
    text-align: center;
    color: #222222;
}

.alert-dialog-description {
    white-space: pre-wrap;
    margin-top: 8px;
    font-family: "ProximaNova", "Helvetica Neue", Helvetica, "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    line-height: 150%;
    color: #888888;
}

.alert-dialog-action {
    display: flex;
    flex-direction: row;
}

.alert-dialog-button {
    flex-grow: 1;
    text-align: center;

    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 48px;
    text-align: center;
    color: #222222;
    height: 48px;
}

.alert-dialog-button-default {
    background: #eeeeee;
}

.alert-dialog-button-keycolor {
    background: #760023;
    color: #ffffff;
}