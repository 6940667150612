.test-list {
    margin-top: 0px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.test-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    align-items: center;
}

.test-confirm-bottom-sheet {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 16px;
}

.test-confirm-bottom-sheet-content {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    margin-right: 8px;
}

.test-confirm-bottom-sheet-title {
    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    text-align: left;
    word-wrap: break-word;
    overflow-wrap: break-word;
    color: #222222;
}

.test-confirm-bottom-sheet-title {
    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    text-align: center;
    word-wrap: break-word;
    overflow-wrap: break-word;
    color: #222222;
}

.test-confirm-bottom-sheet-icon {
    display: flex;
    justify-content: center;
}

.test-confirm-bottom-sheet-description {
    margin-top: 8px;
    white-space: pre;
    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    word-wrap: break-word;
    overflow-wrap: break-word;
    color: #888888;
}

.test-confirm-bottom-sheet-bottom-description {
    margin-top: 24px;
    margin-bottom: 8px;
    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    word-wrap: break-word;
    overflow-wrap: break-word;
    color: #888888;
}

.test-confirm-bottom-sheet-button {
    background: #760023;
    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 60px;
    text-align: center;

    color: #FFFFFF;
    margin-top: 16px;
    border-radius: 12px;
}

.test-list-empty {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 48px);
    width: 100%;
}

.test-list-empty-content {
    margin-top: calc(20vh);
    width: 100%;
}

.test-list-empty-icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 184px;
    height: 184px;
    width: 100%;
}

.test-list-empty-title {
    margin-top: 8px;
    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    text-align: center;
    word-wrap: break-word;
    overflow-wrap: break-word;
    color: #222222;
}

.test-list-empty-description {
    font-family: "ProximaNova", "Helvetica Neue", "Helvetica", "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    overflow-wrap: anywhere;
    text-align: center;
    white-space: normal;
    color: #888888;
}